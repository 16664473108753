@tailwind base;
@tailwind utilities;
@tailwind components;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


* {
    scroll-behavior: smooth;
    box-sizing: border-box;
}


/* Primary Colors
    #95F2BC
    #8CFBFB
*/

iframe {
    position: relative;
    min-width: 400px;
    height: 690px
}

.calendar {
    height: 700px;
    width: 100%;
}

.button {
    border-radius: 5px;
    background: var(--Green, linear-gradient(50deg, #2F855A 5.89%, #68D391 111.03%));
    /* Darker gradient colors */
    color: #FFFFFF;
    /* White text for better contrast */
}

.secondary-button {
    border-radius: 5px;
    background: var(--Green, linear-gradient(94deg, #48BB78 5.89%, #94F3C3 111.03%));
}

.button-full {
    background: linear-gradient(94.33deg, #48BB78 5.89%, #94F3C3 111.03%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topbar {
    background: linear-gradient(94.33deg, #48BB78 5.89%, #94F3C3 111.03%);
}


.bghero {
    background-image: linear-gradient(180deg, #95F2BC 0%, #94F3C3 100%);
    width: 100%;
}

.hero {
    background-image: url('/frontend/images/svg/bg-04.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 85vh;
}

.seo-hero {
    background-image: url('/frontend/images/svg/bg-07.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 85vh;
}

.smm-hero {
    background-image: url('/frontend/images/svg/bg-08.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 65vh;
}

.web-hero {
    background-image: url('/frontend/images/svg/bg-09.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 85vh;
}

.main-hero {
    background-image: url('/frontend/images/svg/bg-12.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 85vh;
}

.ai-hero {
    background-image: url('/frontend/images/svg/bg-11.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 80vh;
}

.hero-reviews {
    background-image: url('/frontend/images/svg/bg-06.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

@media (min-width: 1024px) {
    .hero-leads {
        background-image: url('/frontend/images/svg/bg-05.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 85vh;
    }
}



.ctahero {
    background-image: radial-gradient(100% 752.33% at 0% 0%, #95F2BC 0%, #8CFBFB 100%);
    width: 100%;
}

.bg-03 {
    background-image: url('/frontend/images/svg/bg-03.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 580px;
    width: 100%;
}

.is-circle {
    border-radius: 50%
}

.wrap-1 {
    border: 1px dotted gray;
    width: 300px;
    height: 300px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.object {
    background-color: red;
    width: 30px;
    height: 30px;
    animation: rotate 100s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg) translateX(150px);
    }

    to {
        transform: rotate(360deg) translateX(150px);
    }

}

.ctaction {
    background-image: url('/frontend/images/png/calltoaction-03.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 750px;
    width: 100%;
}


/* @media screen and (min-width: 1280px) {
        .hero-image {
        background-image: url('/frontend/images/svg/bg-02.svg');
        background-repeat: no-repeat;
        background-position: right;
        top: 0;
        right: 0;
        height: 700px
    }
} */

@keyframes scroll {
    0% {
        transform: translateX(50%);
    }

    100% {
        transform: translateX(calc(-100% + 250px));
        /* Adjusted to account for slide width */
    }
}

.slider {
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: auto;
}

.slider .slide-track {
    animation: scroll 60s linear infinite;
    display: flex;
    width: calc(250px * 14);
}

.slider .slide {
    height: 100px;
    width: 250px;
}

.active {
    color: #48BB78;
}

.carousel-cell.is-selected {
    visibility: visible;
}

.carousel-cell {
    visibility: hidden;
}

/* position dots in carousel */
.flickity-page-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
}

/* white circles */
.flickity-page-dots .dot {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: transparent;
    border: 2px solid white;
    border-radius: 50%;
}

/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
    background: green;
}

/* .button {
    width: 170px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    display: inline-block;
} */


.button-2 {
    border: 2px solid #94F3C3;
    background-color: #DFFFF5;
    border-radius: 20px;
    color: #fff;
    transition: .3s;
}

.button-2:hover {
    box-shadow: 8px 8px #94F3C3;
    transition: .3s;
}

.button-3 {
    border: 2px solid #f59e0b;
    background-color: #fef3c7;
    border-radius: 20px;
    color: #fff;
    transition: .3s;
}

.button-3:hover {
    box-shadow: 8px 8px #f59e0b;
    transition: .3s;
}